import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_page_overlay = _resolveComponent("page-overlay")!

  return (_ctx.blog)
    ? (_openBlock(), _createBlock(_component_page_overlay, {
        key: 0,
        title: 'Blog',
        subtitle: _ctx.blog.attributes.title,
        id: _ctx.blog.id
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              (_ctx.blog.attributes.Afbeelding?.data)
                ? (_openBlock(), _createBlock(_component_b_col, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: `${_ctx.VUE_APP_API_IMG_URL}/uploads/${_ctx.blog.attributes.Afbeelding?.data.attributes.hash}${_ctx.blog.attributes.Afbeelding?.data.attributes.ext}`,
                        class: "w-100 mb-3"
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_b_col, { class: "small" }, {
                default: _withCtx(() => [
                  _createElementVNode("small", null, _toDisplayString(_ctx.dateTime(_ctx.blog.attributes.publishedAt)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_Markdown, {
                source: _ctx.blog.attributes.Content,
                html: true
              }, null, 8, ["source"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["subtitle", "id"]))
    : _createCommentVNode("", true)
}