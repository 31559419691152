import { gql } from "@apollo/client/core";
import { getLocale } from '../i18n'
// Apollo client
import { provideApolloClient } from '@vue/apollo-composable'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context';

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.VUE_APP_STRAPI_TOKEN
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: process.env.VUE_APP_API_URL
})

provideApolloClient(new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
}))

// PAGES
export const GET_PAGE_HEADERS = gql`
query($locale: I18NLocaleCode!) {
  pages(locale: $locale) {
    data {
      id
      attributes {
        title: Titel
      }
    }
  }
  forYous(locale: $locale) {
    data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;
export const GET_PAGE_BY_ID = gql`
query($id: ID!, $locale: I18NLocaleCode) {
  page(id: $id, locale: $locale)
  {
    data {
      id
      attributes {
        title: Titel
        image: Afbeelding {
          data {
            attributes {
              url
            }
          }
        }
        content: Content
      }
    }
  }
}
`;

export const GET_FOR_YOU_PAGE_BY_ID = gql`
query($id: ID!, $locale: I18NLocaleCode) {
  forYou(id: $id, locale: $locale)
  {
    data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;

export const GET_FOR_YOU_PAGES = gql`
query {
  forYous {
     data {
      id
      attributes {
        title: Title
        content: Content
      }
    }
  }
}
`;

// POSTS
export const GET_POST_HEADERS = gql`
query($locale: I18NLocaleCode) {
  posts(locale: $locale)
  {
    data {
        id
        attributes {
            titel
            latitude
            longitude
            ReisTijd
            ZoomTijd

        }
    }
  }
}
`;

export const GET_POST_BY_ID = gql`
  query ($id: ID!, $locale: I18NLocaleCode) {
    post(id: $id, locale: $locale) {
      data {
        id
        attributes {
          titel
          longitude
          latitude
          ReisTijd
          ZoomTijd
          vimeoURL
          content
          publishedAt
        }
      }
    }
  }
`;

// BLOG
export const GET_BLOG_HEADERS = gql`
query($locale: I18NLocaleCode!) {
  blogs(locale: $locale) {
    data {
      id
      attributes {
        title: Titel
      }
    }
  }
}
`;
export const GET_BLOG_OVERVIEW = gql`
  query ($locale: I18NLocaleCode!) {
    blogs(locale: $locale) {
      data {
        id
        attributes {
          title: Titel
          content: Content
          image: Afbeelding {
            data {
              attributes {
                hash
                ext
              }
            }
          }
          createdAt
          updatedAt
          publishedAt
          localizations {
            data {
              attributes {
                content: Content
              }
            }
          }
          locale
        }
      }
    }
  }
`;

export const GET_BLOG_BY_ID = gql`
query ($id: ID!, $locale: I18NLocaleCode!) {
  blog(id: $id, locale: $locale) {
    data {
      id
      attributes {
        title: Titel
        Content
        publishedAt
        Afbeelding {
          data {
            attributes {
              hash
              ext
            }
          }
        }
      }
    }
  }
}
`;