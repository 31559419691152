export default {
  NetDeliveryPerformance: {
    graphtype: "1",
    transporttype: "CEP",
    period: "last 4 weeks",
    minvalue: "80.00%",
    maxvalue: "100.00%",
    t1value: "95.00%",
    t2value: "98.00%",
    actualvalue: "97.18%"
  },
  Exceptions: {
    graphtype: "1",
    transporttype: "CEP",
    period: "last 7 days rolling",
    minvalue: "0.00%",
    maxvalue: "20.00%",
    t1value: "",
    t2value: "",
    actualvalue: "4.11%"
  },
  ReductionCO2: {
    graphtype: "2",
    transporttype: "CEP",
    period: "Year-t-year date rolling",
    label1: "",
    value1: "4.75%",
    label2: "Since 2017",
    value2: "23.31%"
  },
  Savings: {
    graphtype: "2",
    transporttype: "CEP",
    period: "Last month",
    label1: "",
    value1: "21,75%",
    label2: "Potential additional saving array(Same SL)",
    value2: "9,31%"
  }
}