import * as am4core from "@amcharts/amcharts4/core"

export default class SketchyFilter extends am4core.Filter {

  constructor() {

    super();

    // Set default properties
    this.width = 200;
    this.height = 200;

    // TEXTURE
    this.addPrimitve("feDropShadow", {
      "in": "SourceGraphic",
      "stdDeviation": "30 0",
      "dx": "-20",
      "dy": "0",
      "flood-color": "#bab9b6",
      "flood-opacity": "0.5",
      "transform" : "(180, 33)",
      "x": "0%",
      "y": "0%",
      "result": "dropShadow"
    });


    // MERGE
  }

  addGroup (type: any, attr: any) {
    const f = this.paper.addGroup(type);
    f.attr(attr);
    this.filterPrimitives.push(f);
    return f;
  }

  addPrimitve (type: any, attr: any) {
    const f = this.paper.add(type);
    f.attr(attr);
    this.filterPrimitives.push(f);
    return f;
  }

  addPrimitveNode (parent: any, type: any, attr: any) {
    const f = this.paper.add(type);
    f.attr(attr);
    parent.add(f);
    return f;
  }

}