
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, } from "vue-router";
import { useI18n } from "vue-i18n";
import { useQuery } from "@vue/apollo-composable";
import { GET_PAGE_BY_ID, GET_FOR_YOU_PAGES } from "../data/graphql";
import PageOverlay from "./PageOverlay.vue";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    PageOverlay,
    Markdown,
  },
  props: {
    title: { type: String, required: false },
  },
  setup () {
    const { t } = useI18n();
    const route = useRoute();
    let forYouPage = ref();
    let forYouFunctions = ref();

    function FetchPage (id: string | string[]): void {
      let localeParam = route.params.locale

      let { onResult, onError } = useQuery(
        GET_PAGE_BY_ID,
        { id: id, locale: localeParam },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        forYouPage.value = result.data.page.data;
      });

      onError(error => {
        console.log(error.graphQLErrors)
        console.log(error.networkError)
      })
    }

    function FetchJobs (): void {
      let { onResult } = useQuery(
        GET_FOR_YOU_PAGES,
        null,
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        forYouFunctions.value = result.data.forYous.data;
      });
    }
    function resize () {
      window.dispatchEvent(new CustomEvent('resize'));
    }

    onMounted(() => {
      FetchPage("3");
      FetchJobs();
    });

    return {
      forYouPage,
      forYouFunctions,
      t,
      VUE_APP_API_IMG_URL: process.env.VUE_APP_API_IMG_URL,
      resize
    };
  },
});
