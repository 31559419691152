import { createRouter, createWebHistory } from "vue-router";
import {
  getLocale,
  setI18nLanguage,
  loadLocaleMessages,
  SUPPORT_LOCALES,
} from "../i18n";

import type { Router, RouteRecordRaw } from "vue-router";
import type { I18n } from "vue-i18n";

import MainLayout from "@/layout/MainLayout.vue";
import PostPage from "@/components/PostPage.vue";
import BlogPage from "@/components/BlogPage.vue";
import BlogOverviewPage from "@/components/BlogOverviewPage.vue";
import PagePage from "@/components/PagePage.vue";
import ForYouPage from "@/components/ForYouPage.vue";
import ContactPage from "@/components/ContactPage.vue";
import Dashboard from "@/components/DashboardWidget.vue";

export function setupRouter(i18n: I18n): Router {
  const locale = getLocale(i18n);

  const routes: RouteRecordRaw[] = [
    {
      path: "/:locale",
      name: "Home",
      component: MainLayout,
      children: [
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "contact",
          name: "Contact",
          component: ContactPage,
        },
        {
          path: "blog",
          name: "BlogOverview",
          component: BlogOverviewPage,
        },
        {
          path: "contact",
          name: "Contact",
          component: ContactPage,
        },
        {
          path: "blog/:id",
          name: "Blog",
          component: BlogPage,
        },
        {
          path: "post/:id",
          name: "Post",
          component: PostPage,
        },
        {
          path: "page/:id",
          name: "Pages",
          component: PagePage,
        },
        {
          path: "for-you",
          name: "ForYou",
          component: ForYouPage,
        }
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: () => `/${locale}`,
    },
  ];

  // create router instance
  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  // navigation guards
  router.beforeEach(async (to) => {
    const paramsLocale = to.params.locale as string;

    // // use locale if paramsLocale is not in SUPPORT_LOCALES
    // if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    //   return `/${locale}`
    // }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale);
    }

    // // set i18n language
    // setI18nLanguage(i18n, paramsLocale)
  });

  return router;
}
