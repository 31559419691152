
import MapMenu from "./../components/MapMenu.vue";
import { store } from "./../data/store";
import { defineComponent, inject } from "vue";
import BlogTicker from "./../components/BlogTicker.vue";
import PagesMenu from "./../components/PagesMenu.vue";
import WorldMap from "./../components/WorldMap.vue";
import DashboardWidget from "./../components/DashboardWidget.vue";

export default defineComponent({
  data () {
    return {
      store,
      showTitle: false,
      showTitleDone: false,
      delayTime: 500,
      visibleTime: 5000,
    };
  },
  computed: {
    isMobile (): boolean {
      return this.$isMobile;
    },
  },
  components: {
    BlogTicker,
    PagesMenu,
    WorldMap,
    DashboardWidget,
    MapMenu,
  },
  mounted () {
    const emitter = inject("emitter") as any; // Inject `emitter`
    // Start animation
    setTimeout(() => {
      if (this.$route.name == "Home") {
        this.showTitle = true;
      } else {
        this.showTitle = false;
        this.showTitleDone = true;
      }
    }, this.delayTime);

    setTimeout(() => {
      this.showTitle = false;
      this.showTitleDone = true;
      emitter.emit("ZoomToLevel2");
    }, this.visibleTime);
  },
});
