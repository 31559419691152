
import { store } from "./../data/store";
import { defineComponent, inject } from "vue";

import VuePlyr from "@skjnldsv/vue-plyr";
import "@skjnldsv/vue-plyr/dist/vue-plyr.css";

export default defineComponent({
  components: {
    VuePlyr,
  },
  inject: ["emitter"],
  props: {
    id: {
      type: String,
      required: true,
      default: "-",
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    showSubScreenSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },
    showReturnToMovieText: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: String,
      required: false,
      default: "",
    },
    vimeoURL: {
      type: String,
      required: false,
      default: "",
    },
    content: {
      type: String,
      required: false,
      default: "...",
    },
    showContactLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    store (): typeof store {
      return store;
    },
    showPageOverlay (): boolean {
      return store.showPageOverlay;
    },
  },
  mounted () {
    const emitter = inject("emitter") as any; // Inject `emitter`
    emitter.emit("openOverlay");
    emitter.emit('closeDashboard')
    emitter.emit('closeMenu')

    emitter.on("closeOverlay", this.fireCloseOverlay);
  },
  data () {
    return {
      activeSubScreen: "video" as "text" | "video",
      playerReady: false,
      plyrOptions: {
        controls: [],
        autoplay: true,
        responsive: true,
        vimeo: { byline: false, portrait: false, title: false, speed: true, transparent: false }
      },
    };
  },
  methods: {
    fireCloseOverlay () {
      this.emitter.emit("closedOverlay");
      this.$router.push("/");
    },
    FullScreen () {
      const plyr = this.$refs.plyrPlayer as typeof VuePlyr;
      plyr.player.fullscreen.enter();
    },
    TogglePlayPause () {
      if (this.$isMobile) {
        const plyr = this.$refs.plyrPlayer as typeof VuePlyr;
        plyr.player.togglePlay()
      }
    },
    Play () {
      const plyr = this.$refs.plyrPlayer as typeof VuePlyr;
      plyr.player.play()
    },
    Pause () {
      const plyr = this.$refs.plyrPlayer as typeof VuePlyr;
      plyr.player.pause()
    }
  }
});
