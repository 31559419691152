
import { defineComponent, onMounted, watch, ref } from "vue";
import { useRoute, RouteParams } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import { GET_BLOG_BY_ID } from "../data/graphql";
import PageOverlay from "./PageOverlay.vue";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    PageOverlay,
    Markdown,
  },
  setup() {
    const route = useRoute();
    let blog = ref();

    function fetchPage(id: string | string[]): void {
      let { onResult } = useQuery(
        GET_BLOG_BY_ID,
        { id: id, locale: route.params.locale },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        blog.value = result.data.blog.data;
      });
    }

    onMounted(() => {
      fetchPage(route.params.id);
    });

    watch(
      () => route.params,
      (toParams: RouteParams) => {
        fetchPage(toParams.id);
      }
    );

    function dateTime(value: Date) {
      return new Date(value).toLocaleString(route.params.locale, { dateStyle: 'medium' } as Intl.DateTimeFormatOptions)
    }

    return {
      blog,
      fetchPage,
      VUE_APP_API_IMG_URL: process.env.VUE_APP_API_IMG_URL,
      dateTime,
    };
  }
});
