import { createApp, h } from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/app.scss";
import { setupRouter } from "./router";
import { setupI18n } from "./i18n";
import nl from "./locales/nl.json";

// MarqueeText
import MarqueeText from "vue-marquee-text-component";
import mitt, { Emitter } from "mitt"; // Import mitt
const emitter = mitt(); // Initialize mitt
// Custom Scrollbar
import CustomScrollbar from "custom-vue-scrollbar";
import "custom-vue-scrollbar/dist/style.css";
import "./scss/scrollbar.scss";

// Apex charts
import VueApexCharts from "vue3-apexcharts";

const SmartPhone =  require("detect-mobile-browser")(false)

const i18n = setupI18n({
  legacy: false,
  locale: "nl",
  fallbackLocale: "nl",
  messages: {
    nl,
  },
});

const Router = setupRouter(i18n);

const app = createApp({
  render: () => h(App),
});

app.config.globalProperties.$emitter = mitt();
app.config.globalProperties.$isMobile = SmartPhone.isAny();

app.use(i18n);
app.use(Router);
app.use(VueApexCharts);
app.use(BootstrapVue);

app.component(MarqueeText.name, MarqueeText);
app.component(CustomScrollbar.name, CustomScrollbar);
app.component("ApexCharts", VueApexCharts);

app.provide("emitter", emitter);
app.provide("isMobile", SmartPhone.isAny());

app.mount("#app");

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    emitter: Emitter<any>; // replace it with the right type
  }
}
