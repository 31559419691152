
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import PageOverlay from "./PageOverlay.vue";

export default defineComponent({
  components: {
    PageOverlay,
  },
  computed: {
    isMobile(): boolean {
      return this.$isMobile;
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  data() {
    return {
      visiblePage: "contact" as "contact" | "address",
      options: [
        { value: null, text: "Selecteer een functie" },
        { value: "LOGISTIEK MANAGER", text: "LOGISTIEK MANAGER" },
        {
          value: "CFO | FINANCIEEL DIRECTEUR",
          text: "CFO | FINANCIEEL DIRECTEUR",
        },
        { value: "INKOOPMANAGER", text: "INKOOPMANAGER" },
        { value: "ICT MANAGER", text: "ICT MANAGER" },
        {
          value: "VERKOOPMANAGER / CUSTOMER SERVICE",
          text: "VERKOOPMANAGER / CUSTOMER SERVICE",
        },
        { value: "BOEKHOUDER", text: "BOEKHOUDER" },
        { value: "GEBRUIKER", text: "GEBRUIKER" },
      ],
      mainProps: {
        width: 60,
        height: 60,
      },
      form: {
        firstname: "",
        companyname: "",
        phone: "",
        lastname: "",
        email: "",
        subject: "",
        context: "",
      },
    };
  },
  methods: {
    onSubmit(event: any) {
      event.preventDefault();
      var body = new String(
        `Hallo, Gaarne ontact opnemen met ${this.form.firstname} ${this.form.lastname} via ${this.form.email} of ${this.form.phone}. Het betreft: ${this.form.context}`
      );
      location.href = `mailto:info@cargowatch.nl?subject=${this.form.subject}&body=${body}`;
    },
  },
});
