
import { store } from './../data/store'
import { defineComponent, onMounted, watch, ref } from 'vue'
import { useRoute, RouteParams } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'
import { GET_POST_BY_ID } from "../data/graphql";
import PageOverlay from './PageOverlay.vue'
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    PageOverlay,
    Markdown
  },
  setup () {
    const route = useRoute()
    let post = ref()

    function fetch (id: string | string[]): void {
      let { onResult } = useQuery(GET_POST_BY_ID, { id: id, locale: route.params.locale }, { fetchPolicy: "network-only" })

      onResult((result) => {
        post.value = result.data.post.data

        store.longitude = post.value.attributes.longitude
        store.latitude = post.value.attributes.latitude
        store.ReisTijd = post.value.attributes.ReisTijd
        store.ZoomTijd = post.value.attributes.ZoomTijd
      })
    }

    onMounted(() => {
      fetch(route.params.id)
    })

    watch(
      () => route.params,
      (toParams: RouteParams) => {
        fetch(toParams.id ?? "3")
      }
    )

    return {
      post
    }
  },
})
