
import { defineComponent, inject, onMounted, Ref, ref, watch } from "vue";
import dashboardData from "./../data/dashboard";
import CargoWatchAPI from "./../data/axios";
import moment from "moment";
import { useI18n } from "vue-i18n";
import { RouteParams, useRoute } from "vue-router";

import { Swiper, SwiperSlide } from 'swiper/vue';
// import required modules
import { Navigation, Autoplay } from "swiper";
// Import Swiper styles
import 'swiper/css';
import "swiper/css/autoplay";
import "swiper/css/navigation";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide
  },
  setup () {
    const { t, locale } = useI18n();
    const route = useRoute();
    let showDashboard = ref(false);
    const emitter = inject("emitter") as any;
    let currentDateString = ref(moment().locale(route.params.locale as moment.LocaleSpecifier).format("MMM Do YYYY  H : mm : ss"));
    let chartData = ref(dashboardData) as Ref<{
      NetDeliveryPerformance: {
        graphtype: string;
        transporttype: string;
        period: string;
        minvalue: string;
        maxvalue: string;
        t1value: string;
        t2value: string;
        actualvalue: string;
      };
      Exceptions: {
        graphtype: string;
        transporttype: string;
        period: string;
        minvalue: string;
        maxvalue: string;
        t1value: string;
        t2value: string;
        actualvalue: string;
      };
      ReductionCO2: {
        graphtype: string;
        transporttype: string;
        period: string;
        label1: string;
        value1: string;
        label2: string;
        value2: string;
      };
      Savings: {
        graphtype: string;
        transporttype: string;
        period: string;
        label1: string;
        value1: string;
        label2: string;
        value2: string;
      };
    }>;

    function slideNext () {
      const swiper = document.querySelector(".swiper") as any;
      swiper.swiper.slideNext();
    }

    function getDashboardData (): Promise<void> {
      return CargoWatchAPI.get("/getkpidata", {
        headers: {
          "cw-api-key": process.env.VUE_APP_CARGOWATCH_SHIPPER_API_KEY,
        },
      })
        .then((response) => {
          chartData.value = response.data;
        })
        .catch((e) => {
          console.error(e);
        });
    }

    function updateDateString (): void {
      currentDateString.value = moment().locale(route.params.locale as moment.LocaleSpecifier).format("MMM Do YYYY  H : mm : ss");
    }

    onMounted(() => {
      getDashboardData();
      setInterval(() => {
        updateDateString()
      }, 400)


      emitter.on('closeDashboard', () => {
        showDashboard.value = false
      })
    });

    watch(
      () => route.params,
      (toParams: RouteParams) => {
        locale.value = toParams.locale.toString();
        getDashboardData();
      }
    );

    function toggleDashboard () {
      showDashboard.value = !showDashboard.value
      if (showDashboard.value) {
        emitter.emit('closeOverlay')
      }
    }

    return {
      chartData,
      t,
      locale,
      showDashboard,
      currentDateString,
      Autoplay,
      Navigation,
      slideNext,
      toggleDashboard
    };
  },
})
