
import { defineComponent, onMounted, watch, ref } from "vue";
import { useQuery } from "@vue/apollo-composable";
import PageOverlay from "./PageOverlay.vue";
import { GET_BLOG_OVERVIEW } from "../data/graphql";
import Markdown from "vue3-markdown-it";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {
    PageOverlay,
    Markdown,
  },
  setup () {
    let route = useRoute();
    let blogs = ref();

    function fetchBlogs (): void {
      let { onResult } = useQuery(
        GET_BLOG_OVERVIEW,
        {
          locale: route.params.locale,
        },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        blogs.value = result.data.blogs.data;
      });
    }

    onMounted(() => {
      fetchBlogs();
    });

    watch(
      () => route.params,
      () => {
        fetchBlogs();
      }
    );

    return {
      blogs,
      fetchBlogs,
      VUE_APP_API_IMG_URL: process.env.VUE_APP_API_IMG_URL,
      route,
    };
  },
  methods: {
    formatDate (x: any): string {
      return new Date(x.attributes.publishedAt).toLocaleString(
        this.route.params.locale,
        { dateStyle: "medium" } as Intl.DateTimeFormatOptions
      );
    },
  },
});
