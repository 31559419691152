
import { defineComponent, onMounted, ref, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { GET_PAGE_HEADERS } from "../data/graphql";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { SUPPORT_LOCALES } from "../i18n";
import _ from "lodash";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();
    let pages = ref();
    let forYouSubMenuItems = ref();
    let forYouMenu = ref(false);
    let stillOnMenu = ref(false);

    function fetch(): void {
      let { onResult } = useQuery(
        GET_PAGE_HEADERS,
        { locale: route.params.locale },
        { fetchPolicy: "network-only" }
      );
      onResult((result) => {
        pages.value = _.orderBy(result.data.pages.data, "id", "desc");
        forYouSubMenuItems.value = result.data.forYous.data;
      });
    }

    function switchLocale(locale: string): void {
      router.push({ path: `/${locale}` });
    }

    function showForYouMenu(shouldShow: boolean): void {
      if (!shouldShow) {
        setTimeout(() => {
          if (!stillOnMenu.value) {
            forYouMenu.value = false;
          }
        }, 500);
      } else {
        forYouMenu.value = shouldShow;
      }
    }

    watch(
      () => route.params,
      () => {
        fetch();
      }
    );

    onMounted(() => {
      fetch();
    });

    return {
      t,
      supportLocales: SUPPORT_LOCALES,
      pages,
      forYouSubMenuItems,
      switchLocale,
      FOR_YOU_PAGE: 3,
      showForYouMenu,
      forYouMenu,
      stillOnMenu,
    };
  },
});
