
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, RouteParams } from "vue-router";
import { useI18n } from "vue-i18n";
import { useQuery } from "@vue/apollo-composable";
import { GET_PAGE_BY_ID } from "../data/graphql";
import PageOverlay from "./PageOverlay.vue";
import Markdown from "vue3-markdown-it";

export default defineComponent({
  components: {
    PageOverlay,
    Markdown,
  },
  props: {
    title: { type: String, required: false },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    let page = ref();
    let pageImage = ref();

    function fetch(id: string | string[]): void {
      let gql = GET_PAGE_BY_ID;
      let { onResult, onError } = useQuery(
        gql,
        { id: id, locale: route.params.locale },
        { fetchPolicy: "no-cache" }
      );

      onResult((result) => {
        page.value = result.data.page.data;
        pageImage.value = (result.data.page.data.attributes.image.data) ? process.env.VUE_APP_API_IMG_URL + result.data.page.data.attributes.image.data.attributes.url : ''
      });

      onError(error => {
        console.log(error.graphQLErrors)
        console.log(error.networkError)
      })
    }

    onMounted(() => {
      fetch(route.params.id);
    });

    watch(
      () => route.params,
      (toParams: RouteParams) => {
        fetch(toParams.id ?? "3");
      }
    );

    return {
      page,
      pageImage,
      t,
      VUE_APP_API_IMG_URL: process.env.VUE_APP_API_IMG_URL,
    };
  },
});
