
import { Post } from "./../types";
import { store } from "./../data/store";
import { defineComponent, onMounted, watch, ref, PropType, inject } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@vue/apollo-composable";
import { GET_POST_HEADERS } from "../data/graphql";

export default defineComponent({
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object as PropType<{ x: Number; y: Number }>,
      default: () => ({ x: 0, y: 0 }),
    },
  },
  emits: ["postClicked"],
  setup () {
    const route = useRoute();
    let posts = ref();
    let menuStore = ref(store)
    const emitter = inject("emitter") as any;
    const isMobile = inject("isMobile") as any;

    function fetch (): void {
      let { onResult } = useQuery(
        GET_POST_HEADERS,
        { locale: route.params.locale },
        { fetchPolicy: "network-only" }
      );

      onResult((result) => {
        posts.value = result.data.posts.data;
      });
    }

    function postClicked (post: Post) {
      store.showPageOverlay = false;
      if (isMobile) {
        menuStore.value.showMenu = false;
      }
      store.activeSubScreen = "video";
      emitter.emit("postClicked", post);
    }

    onMounted(() => {
      fetch();
      if (isMobile) {
        emitter.on('openOverlay', () => {
          menuStore.value.showMenu = false
        })
        emitter.on('closeMenu', () => {
          menuStore.value.showMenu = false
        })
      }
    });

    watch(
      () => route.params,
      () => {
        fetch();
      }
    );

    function toggleMenu (): void {
      if (!menuStore.value.showMenu) {
        emitter.emit('closeOverlay')
        emitter.emit('closeDashboard')
      }
      menuStore.value.showMenu = !menuStore.value.showMenu
    }

    return {
      posts,
      postClicked,
      menuStore,
      toggleMenu
    };
  },
});
