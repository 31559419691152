import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Markdown = _resolveComponent("Markdown")!
  const _component_page_overlay = _resolveComponent("page-overlay")!

  return (_ctx.post)
    ? (_openBlock(), _createBlock(_component_page_overlay, {
        key: 0,
        title: _ctx.post.attributes.titel,
        showSubScreenSwitch: true,
        id: _ctx.post.id,
        showContactLink: true,
        vimeoURL: _ctx.post.attributes.vimeoURL,
        showReturnToMovieText: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Markdown, {
            source: _ctx.post.attributes.content,
            html: true
          }, null, 8, ["source"])
        ]),
        _: 1
      }, 8, ["title", "id", "vimeoURL"]))
    : _createCommentVNode("", true)
}