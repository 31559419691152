import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column align-items-start",
  id: "zoomControls"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.cssProps)
  }, [
    (_ctx.showMapComponents)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("img", {
            src: "/img/plus-sign.svg",
            width: "20",
            height: "20",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.mapZoomIn && _ctx.mapZoomIn(...args))),
            class: "mb-2",
            style: {"cursor":"pointer","padding":"2px"}
          }),
          _createElementVNode("img", {
            src: "/img/minus-sign.svg",
            width: "20",
            height: "20",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.mapZoomOut && _ctx.mapZoomOut(...args))),
            style: {"cursor":"pointer","padding":"2px"}
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      id: "mapsdiv",
      class: _normalizeClass({ loading: !_ctx.showMapComponents, loaded: _ctx.showMapComponents })
    }, null, 2)
  ], 4))
}