
import { store } from "./../data/store";
import { defineComponent, inject } from "vue";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata from "@amcharts/amcharts4-geodata/worldLow";
import { Post } from "./../types";
import SketchyFilter from "./../data/filters";
import sexagesimal from "@mapbox/sexagesimal";

function formatCoords (latitude: number, longitude: number): string {
  const lat = sexagesimal.coordToDMS(latitude, "lat");
  const lon = sexagesimal.coordToDMS(longitude, "lon");
  return `${latitude.toFixed(4)}° ${lat.dir}<br />${longitude.toFixed(4)}° ${lon.dir
    }`;
}

const CARGOWATCH_LOCATION = {
  latitude: 52.180175,
  longitude: 5.050515,
  formattedCoords: formatCoords(52.180175, 5.050515),
};
const GLOBAL_CENTER_POINT = {
  latitude: 30.222196,
  longitude: 5.050515,
  formattedCoords: formatCoords(30.222196, 5.050515),
};
const GLOBAL_MOBILE_CENTER_POINT = {
  latitude: 27,
  longitude: -30.414932,
};
const RESET_TO_HOME_TIME_IN_SECONDS = 0.8;
let animationTimers = [] as number[];
export default defineComponent({
  props: {
    showMapComponents: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      store,
      showCargoWatchCoords: true,
      map: undefined as unknown as am4maps.MapChart,
      tooltips: [] as am4core.Tooltip[],
      markerSeries: undefined as unknown as am4maps.MapImageSeries,
      mapLineSeries: undefined as unknown as am4maps.MapLineSeries,
      menuLocation: { x: 0, y: 0 } as { x: number; y: number },
      menuCoords: { latitude: "", longitude: "" } as {
        latitude: string;
        longitude: string;
      },
      markers: [] as { latitude: number; longitude: number }[],
      lines: [
        CARGOWATCH_LOCATION, // CargoWatch starting point
      ] as any[],
    };
  },
  methods: {
    closeMenu (): void {
      this.store.showMenu = false;
    },
    navigateToNextLocation (post: Post): void {
      const coords = {
        latitude: parseFloat(post.attributes.latitude),
        longitude: parseFloat(post.attributes.longitude),
        formattedCoords: formatCoords(
          parseFloat(post.attributes.latitude),
          parseFloat(post.attributes.longitude)
        ),
      };
      this.mapLineSeries.mapLines.clear();
      this.markerSeries.data = [];
      let line = this.mapLineSeries.mapLines.create();

      line.multiGeoLine = [[CARGOWATCH_LOCATION, coords]];
      line.id = "myline";
      line.setClassName();

      this.markerSeries.data.push(coords);

      // 1: RESET to starting point
      if (this.$isMobile) {
        this.map.zoomToGeoPoint(
          GLOBAL_MOBILE_CENTER_POINT,
          4,
          true,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      }
      let that = this;

      animationTimers[0] = window.setTimeout(function () {
        // 2: Navigate and zoom to location
        that.map.zoomToGeoPoint(coords, 5, true, store.ZoomTijd * 1000);
        animationTimers[1] = window.setTimeout(function () {
          // 3: RESET to global center point
          if (that.$isMobile) {
            that.map.zoomToGeoPoint(
              GLOBAL_MOBILE_CENTER_POINT,
              2,
              true,
              RESET_TO_HOME_TIME_IN_SECONDS * 1000
            );
          } else {
            that.map.zoomToGeoPoint(
              GLOBAL_CENTER_POINT,
              2,
              true,
              RESET_TO_HOME_TIME_IN_SECONDS * 1000
            );
          }
          // 4: Open page overlay
          that.store.showPageOverlay = true;
        }, store.ZoomTijd * 1000);
      }, RESET_TO_HOME_TIME_IN_SECONDS * 1000);
      this.markerSeries.validateData();
    },
    navigate (location: string): void {
      this.$router.push({ name: location });
    },
    appendComponents (): void {
      let imageSeries = this.map.series.push(
        new am4maps.MapImageSeries()
      ) as am4maps.MapImageSeries;
      let imageSeriesTemplate = imageSeries.mapImages.template;

      imageSeriesTemplate.propertyFields.latitude = "latitude";
      imageSeriesTemplate.propertyFields.longitude = "longitude";

      // Add data for the three cities
      imageSeries.data = [
        {
          latitude: 52.033175,
          longitude: 5.146515,
          formattedCoords: formatCoords(52.180175, 5.050515),
          title: "CargoWatch",
        },
      ];

      // Logo Cargowatch
      let cargoWatchLogo = imageSeriesTemplate.createChild(
        am4core.Image
      ) as am4core.Image;
      cargoWatchLogo.id = "CargowatchLogo";
      cargoWatchLogo.href = "/img/cargowatch-logo.svg";
      cargoWatchLogo.width = 100;
      cargoWatchLogo.height = 92;
      cargoWatchLogo.nonScaling = true;
      cargoWatchLogo.horizontalCenter = "right";
      cargoWatchLogo.verticalCenter = "bottom";
      let that = this
      cargoWatchLogo.events.on("hit", function () { 
        that.$router.push("/");
      })

      cargoWatchLogo.tooltip = new am4core.Tooltip();
      cargoWatchLogo.tooltip.ignoreBounds = true;
      cargoWatchLogo.tooltip.getStrokeFromObject = false;
      cargoWatchLogo.tooltip.getFillFromObject = false;
      cargoWatchLogo.tooltip.background.fill = am4core.color("rgba(0,0,0, 0)");
      cargoWatchLogo.tooltip.background.stroke =
        am4core.color("rgba(0,0,0, 0)");
      cargoWatchLogo.showTooltipOn = "always";
      cargoWatchLogo.tooltip.dy = 40;
      cargoWatchLogo.tooltipHTML =
        '<span class="markerTooltipText cargoWatchCoords">{formattedCoords}</span>';
      cargoWatchLogo.showTooltip();
      this.showCargoWatchCoords = true;

      // Post coords
      this.markerSeries = this.map.series.push(
        new am4maps.MapImageSeries()
      ) as am4maps.MapImageSeries;
      let imageSeriesPostsTemplate = this.markerSeries.mapImages.template;
      // Logo Cargowatch
      let circle = imageSeriesPostsTemplate.createChild(am4core.Circle);
      circle.radius = 5;
      circle.fill = am4core.color("rgb(211,216,0)");
      circle.stroke = am4core.color("#FFFFFF");
      circle.strokeWidth = 1;
      circle.nonScaling = true;
      circle.nonScalingStroke = true;

      // TOOLTIP
      circle.align = "right";
      circle.valign = "middle";

      circle.tooltip = new am4core.Tooltip();
      circle.tooltip.ignoreBounds = true;
      circle.tooltip.getStrokeFromObject = false;
      circle.tooltip.getFillFromObject = false;
      circle.showTooltipOn = "always";
      circle.tooltipHTML =
        '<span class="markerTooltipText">{formattedCoords}</span>';
      circle.tooltip.background.fill = am4core.color("rgba(0,0,0, 0)");
      circle.tooltip.background.stroke = am4core.color("rgba(0,0,0, 0)");
      circle.showTooltip();

      imageSeriesPostsTemplate.propertyFields.latitude = "latitude";
      imageSeriesPostsTemplate.propertyFields.longitude = "longitude";

      this.mapLineSeries = this.map.series.push(
        new am4maps.MapLineSeries()
      ) as am4maps.MapLineSeries;
      let mapLineTemplate = this.mapLineSeries.mapLines.template;

      mapLineTemplate.stroke = am4core.color("rgb(200, 200, 200)");
      mapLineTemplate.strokeWidth = 1;
      mapLineTemplate.nonScalingStroke = true;
      mapLineTemplate.shortestDistance = false; // straight line
      this.mapLineSeries.data = [
        {
          multiGeoLine: [this.lines],
        },
      ];

      // Add lines
      let lineSeries = this.map.series.push(new am4maps.MapArcSeries());
      lineSeries.mapLines.template.line.strokeWidth = 1;
      lineSeries.mapLines.template.line.stroke = am4core.color("#cbdb2f");
      lineSeries.mapLines.template.line.nonScalingStroke = true;
      lineSeries.mapLines.template.line.strokeDasharray = "1,1";
      lineSeries.zIndex = 10;

      // Add line bullets
      let cities = this.map.series.push(new am4maps.MapImageSeries());
      cities.mapImages.template.nonScaling = true;

      function addLine (from, to, offset) {
        let line = lineSeries.mapLines.create();
        let boatlineDashNumber = Math.floor(Math.random() * (4 - 1 + 1) + 1)
        line.imagesToConnect = [from, to];
        line.line.controlPointDistance = offset;
        line.userClassName = "boatLine_dash" + boatlineDashNumber;

        return line;
      }

      function addCity (coords) {
        let city = cities.mapImages.create();
        city.latitude = coords.latitude;
        city.longitude = coords.longitude;
        return city;
      }

      function addLineToCityOnMapWithOffset (
        from,
        to,
        offsetMin,
        offsetMax,
        curveDirection = true,
        numberOffLines = 3
      ) {
        for (let i = 0; i < numberOffLines; i++) {
          let offset = Math.floor(
            Math.random() * (offsetMax - offsetMin + 1) + offsetMin
          );
          offset = curveDirection ? (offset * -1) / 1000 : offset / 1000;

          addLine(from, to, offset);
        }
      }

      let netherlands = addCity({
        latitude: 51.54376417265371,
        longitude: 2.6296979380093966,
      });
      let west_france = addCity({
        latitude: 49.22235161067164,
        longitude: -5.822437881741659,
      });
      let north_portugal = addCity({
        latitude: 43.75193375859522,
        longitude: -10.545689500107487,
      });
      let south_portugal = addCity({
        latitude: 36.53329836196405,
        longitude: -9.519209810132423,
      });
      let north_brazil = addCity({
        latitude: -4.885937266468122,
        longitude: -34.35887536598699,
      });
      let venezuela = addCity({
        latitude: 14.924337812976747,
        longitude: -76.06047159729415,
      });
      let texas_usa = addCity({
        latitude: 28.485408480078487,
        longitude: -95.0034040627162,
      });
      let south_brazil = addCity({
        latitude: -25.33527943536001,
        longitude: -44.84902810124058,
      });
      let south_africa = addCity({
        latitude: -34.78546927258766,
        longitude: 17.663847058337804,
      });

      addLineToCityOnMapWithOffset(
        netherlands,
        west_france,
        100,
        110,
        false,
        5
      );
      addLineToCityOnMapWithOffset(
        west_france,
        north_portugal,
        121,
        125,
        true,
        5
      );
      addLineToCityOnMapWithOffset(
        north_portugal,
        south_portugal,
        121,
        125,
        true,
        5
      );
      addLineToCityOnMapWithOffset(
        south_portugal,
        north_brazil,
        100,
        125,
        true,
        5
      );
      addLineToCityOnMapWithOffset(
        north_brazil,
        venezuela,
        107,
        134,
        true,
        5
      );
      addLineToCityOnMapWithOffset(
        north_brazil,
        south_brazil,
        180,
        200,
        false,
        5
      );
      addLineToCityOnMapWithOffset(
        venezuela,
        texas_usa,
        20,
        25,
        true,
        5
      );
      addLineToCityOnMapWithOffset(
        south_africa,
        south_brazil,
        20,
        25,
        true,
        10
      );
    },
    mapZoomIn (): void {
      this.map.zoomIn();
    },
    mapZoomOut (): void {
      this.map.zoomOut();
    },
    createMap (): void {
      this.map = am4core.create("mapsdiv", am4maps.MapChart);
      this.map.seriesContainer.draggable = true;

      this.map.seriesContainer.resizable = false;
      if (this.$isMobile) {
        this.map.homeGeoPoint = GLOBAL_MOBILE_CENTER_POINT;
        this.map.homeZoomLevel = 4;
        this.map.minZoomLevel = 3;
      } else {
        this.map.homeGeoPoint = GLOBAL_CENTER_POINT;
        this.map.homeZoomLevel = 1;
      }
      this.map.maxZoomLevel = 10;
      // Set map definition
      this.map.geodata = am4geodata;

      // Set projection
      this.map.projection =
        new am4maps.projections.Miller() as am4maps.projections.Miller;
      // Create map polygon series
      var polygonSeries = this.map.series.push(
        new am4maps.MapPolygonSeries()
      ) as am4maps.MapPolygonSeries;
      polygonSeries.exclude = ["AQ"]; // Exclude Antarctique

      // Make map load polygon (like country names) data from GeoJSON
      // Configure series
      let polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.fill = am4core.color("rgb(252,251,253)");
      polygonSeries.useGeodata = true;

      let shadow = polygonSeries.filters.push(
        new SketchyFilter()
      ) as am4core.Filter;
      shadow.nonScaling = true;
    },
  },
  mounted () {
    this.createMap();

    const emitter = inject("emitter") as any; // Inject `emitter`

    emitter.on("postClicked", (post: Post) => {
      this.showCargoWatchCoords = false;
      // Kill previous animation timers
      animationTimers.forEach((t) => {
        clearTimeout(t);
      });
      this.navigateToNextLocation(post);
    });

    emitter.on("ZoomToLevel2", () => {
      // 3: RESET to global center point
      if (this.$isMobile) {
        this.map.zoomToGeoPoint(
          GLOBAL_MOBILE_CENTER_POINT,
          2,
          true,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      } else {
        this.map.zoomToGeoPoint(
          GLOBAL_CENTER_POINT,
          2,
          true,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      }
    });

    emitter.on("openOverlay", () => {
      if (this.$isMobile) {
        this.map.zoomToGeoPoint(
          GLOBAL_MOBILE_CENTER_POINT,
          4,
          true,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      } else {
        this.map.zoomToGeoPoint(
          GLOBAL_CENTER_POINT,
          2,
          true,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      }
    });

    emitter.on("closedOverlay", () => {
      this.showCargoWatchCoords = true;
      this.mapLineSeries.mapLines.clear();
      this.markerSeries.data = [];
      if (this.$isMobile) {
        this.map.zoomToGeoPoint(
          GLOBAL_MOBILE_CENTER_POINT,
          1,
          false,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      } else {
        this.map.zoomToGeoPoint(
          GLOBAL_CENTER_POINT,
          1,
          false,
          RESET_TO_HOME_TIME_IN_SECONDS * 1000
        );
      }
    });

  },
  computed: {
    cssProps () {
      return {
        "--animation-duration": store.ReisTijd + "s",
        "--show-cargowatch-coords": this.showCargoWatchCoords ? 1 : 0,
      };
    },
  },
  beforeUnmount () {
    if (this.map) {
      this.map.dispose();
    }
  },
  watch: {
    showMapComponents (newValue) {
      if (newValue) {
        this.appendComponents();
      }
    },
  },
});
